import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <SEO title="Posten nicht gefunden" />
    <h1>Posten nicht gefunden</h1>
  </>
)

export default NotFoundPage
